export default [
  {
    path: '/urun/urunler',
    name: 'urunler',
    redirect: '/urunler/listele',
    component: () => import(/* webpackChunkName: "urunler" */ '@/views/urunler/index.vue'),
    children: [
      {
        path: '/urunler/listele',
        name: 'urun-listele',
        component: () => import(/* webpackChunkName: "sayfa-listele" */ '@/views/urunler/listele.vue'),
      },
      {
        path: '/urunler/ekle',
        name: 'urun-ekle',
        component: () => import(/* webpackChunkName: "sayfa-listele" */ '@/views/urunler/urunEkle.vue'),
      },
      {
        path: '/urunler/guncelle/:k_no?',
        name: 'urun-guncelle',
        component: () => import(/* webpackChunkName: "urun-guncelle" */ '@/views/urunler/urunEkle.vue'),
      },
      {
        path: '/urunler/hareketler/:k_no?',
        name: 'urun-hareketler',
        component: () => import(/* webpackChunkName: "urun-hareketler" */ '@/views/urunler/hareketler.vue'),
      },
      {
        path: '/urun/urun/sirala',
        name: 'urun-sirala',
        component: () =>
          import(/* webpackChunkName: "sayfa-sirala" */ '@/views/urunler/urunSirala.vue'),
        meta: { title: 'Ürün Sırala', noCache: true },
      },
    ],
  },
];
