import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  harc_ulke: [],
};
const getters = {
  getHarcUlke: (state) => state.harc_ulke,
};
const mutations = {
  SET_HARC_ULKE(state, payload) {
    state.harc_ulke = payload;
  },
  SET_HARC_ULKE_PUSH(state, payload) {
    state.harc_ulke.push(payload);
  },
  SET_HARC_ULKE_UPDATE(state, payload) {
    let index = state.harc_ulke.findIndex((x) => x.k_no == payload.k_no);
    state.harc_ulke[index] = payload;
  },
  SET_HARC_ULKE_DELETE(state, k_no) {
    let altindex = state.harc_ulke.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.harc_ulke.splice(altindex, 1);
    }
    let index = state.harc_ulke.findIndex((x) => x.k_no == k_no);
    state.harc_ulke.splice(index, 1);
  },
};
const actions = {
  async harcUlkeListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.get('/tanimlamalar/harc-ulke-listele', { params: payload }).then((res) => {
      commit('SET_HARC_ULKE', res.data.data);
      return res;
    });
  },
  async harcUlkeEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/harc-ulke-ekle', data).then((res) => {
      commit('SET_HARC_ULKE_PUSH', res.data.data);
      return res;
    });
  },
  async harcUlkeGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/harc-ulke-guncelle', data).then((res) => {
      commit('SET_HARC_ULKE_UPDATE', res.data.data);
      return res;
    });
  },
  async harcUlkeStatuGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/harc-ulke-statu-guncelle', data).then((res) => {
      return res;
    });
  },
  async harcUlkeSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/harc-ulke-sil', payload).then((res) => {
      commit('SET_HARC_ULKE_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
