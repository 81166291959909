import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  splash_bildirimler: [],
};
const getters = {
  getSplashBildirimler: (state) => state.splash_bildirimler,
};
const mutations = {
  SET_SPLASH_BIL(state, payload) {
    state.splash_bildirimler = payload;
  },
  SET_SPLASH_BIL_PUSH(state, payload) {
    state.splash_bildirimler.push(payload);
  },
  SET_SPLASH_BIL_UPDATE(state, payload) {
    let index = state.splash_bildirimler.findIndex((x) => x.k_no == payload.k_no);
    state.splash_bildirimler[index] = payload;
  },
  SET_SPLASH_BIL_DELETE(state, k_no) {
    let altindex = state.splash_bildirimler.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.splash_bildirimler.splice(altindex, 1);
    }
    let index = state.splash_bildirimler.findIndex((x) => x.k_no == k_no);
    state.splash_bildirimler.splice(index, 1);
  },
};
const actions = {
  async splashBildirimListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/magaza/splash-bildirim-listele', payload).then((res) => {
      commit('SET_SPLASH_BIL', res.data.data);
      return res;
    });
  },
  async splashBildirimEkle({ commit }, data) {
    return await axiosIns.post('/magaza/splash-bildirim-ekle', data).then((res) => {
      commit('SET_SPLASH_BIL_PUSH', res.data.data);
      return res;
    });
  },
  async splashBildirimGuncelle({ commit }, data) {
    return await axiosIns.post('/magaza/splash-bildirim-guncelle', data).then((res) => {
      commit('SET_SPLASH_BIL_UPDATE', res.data.data);
      return res;
    });
  },
  async splashBildirimSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/magaza/splash-bildirim-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async splashBildirimSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/magaza/splash-bildirim-sil', payload).then((res) => {
      commit('SET_SPLASH_BIL_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
