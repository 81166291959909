import axiosIns from '@/libs/axios';
const state = {
  medya_ortam: [],
  medya_ortam_dosyalar: [],
};
const getters = {
  getMedyaOrtam: (state) => state.medya_ortam,
  getMedyaOrtamDosyalar: (state) => state.medya_ortam_dosyalar,
};
const mutations = {
  SET_MEDYA_ORTAM(state, payload) {
    state.medya_ortam = payload;
  },
  SET_MEDYA_ORTAM_PUSH(state, payload) {
    state.medya_ortam.push(payload);
  },
  SET_MEDYA_ORTAM_UPDATE(state, payload) {
    let index = state.medya_ortam.findIndex((x) => x.k_no == payload.k_no);
    state.medya_ortam[index] = payload;
  },
  SET_MEDYA_ORTAM_DELETE(state, k_no) {
    let altindex = state.medya_ortam.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.medya_ortam.splice(altindex, 1);
    }
    let index = state.medya_ortam.findIndex((x) => x.k_no == k_no);
    state.medya_ortam.splice(index, 1);
  },

  SET_MEDYA_ORTAM_DOSYA(state, payload) {
    state.medya_ortam_dosyalar = payload;
  },

  SET_MEDYA_ORTAM_DOSYA_UPDATE(state, payload) {
    let index = state.medya_ortam_dosyalar.findIndex((x) => x.k_no == payload.k_no);
    state.medya_ortam_dosyalar[index] = payload;

  },

  SET_MEDYA_ORTAM_DOSYA_DELETE(state, k_no) {
    let index = state.medya_ortam_dosyalar.findIndex((x) => x.k_no == k_no);
    state.medya_ortam_dosyalar.splice(index, 1);
  },
};
const actions = {
  async medyaOrtamListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/magaza/medya-ortam-listele', payload).then((res) => {
      commit('SET_MEDYA_ORTAM', res.data.data);
      return res;
    });
  },
  async medyaOrtamEkle({ commit }, data) {
    return await axiosIns.post('/magaza/medya-ortam-ekle', data).then((res) => {
      commit('SET_MEDYA_ORTAM_PUSH', res.data.data);
      return res;
    });
  },
  async medyaOrtamGuncelle({ commit }, data) {
    return await axiosIns.post('/magaza/medya-ortam-guncelle', data).then((res) => {
      commit('SET_MEDYA_ORTAM_UPDATE', res.data.data);
      return res;
    });
  },

  async medyaOrtamSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/magaza/medya-ortam-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_MEDYA_ORTAM_DELETE', k_no);
      }
      return res;
    });
  },

  async medyaOrtamFindOne({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.get('/magaza/medya-ortam-find-one', { params: payload }).then((res) => {
      return res;
    });
  },

  async medyaOrtamDosyaListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.get('/magaza/medya-ortam-dosya-listele', { params: payload }).then((res) => {
      commit('SET_MEDYA_ORTAM_DOSYA', res.data.data);
      return res;
    });
  },

  async medyaOrtamDosyaEkle({ commit }, data) {
    return await axiosIns
      .post('/magaza/medya-ortam-dosya-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        // commit('SET_MEDYA_ORTAM_UPDATE', res.data.data);
        return res;
      });
  },

  async medyaOrtamDosyaGuncelle({ commit }, data) {
    return await axiosIns
      .post('/magaza/medya-ortam-dosya-guncelle', data)
      .then((res) => {
        commit('SET_MEDYA_ORTAM_DOSYA_UPDATE', res.data.data);
        return res;
      });
  },

  async medyaOrtamDosyaSil({ commit }, data) {
    return await axiosIns.post('/magaza/medya-ortam-dosya-sil', data).then((res) => {
      commit('SET_MEDYA_ORTAM_DOSYA_DELETE', data.k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
