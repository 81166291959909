export default [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/LoginPage.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  // {
  //   path: '/auth/register',
  //   name: 'register',
  //   component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Register.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/auth/forgot-password',
  //   name: 'forgot-password',
  //   component: () => import(/* webpackChunkName: "forgot-password" */ '@/views/auth/ForgotPassword.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/auth/reset-password/:resetPasswordToken?',
  //   name: 'reset-password',
  //   component: () => import(/* webpackChunkName: "reset-password" */ '@/views/auth/ResetPassword.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/pages/miscellaneous/not-authorized',
  //   name: 'misc-not-authorized',
  //   component: () => import(/* webpackChunkName: "misc-not-authorized" */ '@/views/miscellaneous/NotAuthorized.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //   },
  // },
  // {
  //   path: '/error/error-404',
  //   name: 'error-404',
  //   component: () => import(/* webpackChunkName: "error-404" */ '@/views/error/Error404.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '*',
    redirect: '/error/error-404',
  },
];
