import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  bildirim_formu: [],
};
const getters = {
  getETicBildirimFormlari: (state) => state.bildirim_formu,
};
const mutations = {
  SET_ETICBILDIRIM(state, payload) {
    state.bildirim_formu = payload;
  },
  SET_ETICBILDIRIM_PUSH(state, payload) {
    state.bildirim_formu.push(payload);
  },
  SET_ETICBILDIRIM_UPDATE(state, payload) {
    let index = state.bildirim_formu.findIndex((x) => x.k_no == payload.k_no);
    state.bildirim_formu[index] = payload;
  },
  SET_ETICBILDIRIM_ONAY_UPDATE(state, payload) {
    let index = state.bildirim_formu.findIndex((x) => x.k_no == payload.k_no);
    if (index != -1) state.bildirim_formu[index].statu = true;
  },
  SET_ETICBILDIRIM_DELETE(state, k_no) {
    let index = state.bildirim_formu.findIndex((x) => x.k_no == k_no);
    state.bildirim_formu.splice(index, 1);
  },
};
const actions = {
  async eTicBildirimFormListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/siparisler/e-tic-bildirim-form-listele', payload).then((res) => {
      commit('SET_ETICBILDIRIM', res.data.data);
      return res;
    });
  },
  async eTicBildirimFormEkle({ commit }, data) {
    return await axiosIns.post('/siparisler/e-tic-bildirim-form-ekle', data).then((res) => {
      commit('SET_ETICBILDIRIM_PUSH', res.data.data);
      return res;
    });
  },
  async eTicBildirimFormGuncelle({ commit }, data) {
    return await axiosIns.post('/siparisler/e-tic-bildirim-form-guncelle', data).then((res) => {
      commit('SET_ETICBILDIRIM_UPDATE', res.data.data);
      return res;
    });
  },
  async eTicBildirimFormSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/siparisler/e-tic-bildirim-form-sil', payload).then((res) => {
      commit('SET_ETICBILDIRIM_DELETE', k_no);
      return res;
    });
  },
  async eTicBildirimFormOnayla({ commit }, data) {
    return await axiosIns.post('/siparisler/e-tic-bildirim-form-onayla', data).then((res) => {
      if (res.data.success) {
        commit('SET_ETICBILDIRIM_ONAY_UPDATE', data);
      }
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
