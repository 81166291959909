import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import VuexPersistence from 'vuex-persist';
import Crypto from 'crypto-js';

const storageKey = 'int_e-ticaret';
const encryptionToken = 'int_e-ticaret-encryto';
const vuexLocal = new VuexPersistence({
  storage: {
    getItem: () => {
      // Get the store from local storage.
      const store = window.localStorage.getItem(storageKey);
      if (store) {
        try {
          const bytes = Crypto.AES.decrypt(store, encryptionToken);
          // console.log(JSON.parse(bytes.toString(Crypto.enc.Utf8)));
          return JSON.parse(bytes.toString(Crypto.enc.Utf8));
        } catch (e) {
          window.localStorage.removeItem(storageKey);
        }
      }
      return null;
    },
    setItem: (key, value) => {
      const store = Crypto.AES.encrypt(value, encryptionToken).toString();
      return window.localStorage.setItem(storageKey, store);
    },
    removeItem: () => window.localStorage.removeItem(storageKey),
  },
  modules: ['app', 'auth', 'ayarDiller'],
});

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  plugins: [vuexLocal.plugin],
});

export default store;
