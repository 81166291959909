export default [
  {
    path: '/tanimlama/kategoriler',
    name: 'kategoriler',
    component: () => import(/* webpackChunkName: "kategoriler" */ '@/views/tanimlamalar/kategoriler/index.vue'),
  },
  {
    path: '/tanimlama/bolgeler',
    name: 'bolgeler',
    component: () => import(/* webpackChunkName: "bolgeler" */ '@/views/tanimlamalar/bolgeler/index.vue'),
    meta: { title: 'Bölgeler', noCache: true }
  },
  {
    path: '/tanimlama/teslimat-saatler',
    name: 'teslimat-saatler',
    component: () => import(/* webpackChunkName: "teslimat-saatler" */ '@/views/tanimlamalar/teslimatSaat/index.vue'),
  },
  {
    path: '/tanimlama/markalar',
    name: 'markalar',
    component: () => import(/* webpackChunkName: "markalar" */ '@/views/tanimlamalar/markalar/index.vue'),
  },
  {
    path: '/tanimlama/stok-birimleri',
    name: 'stok-birimleri',
    component: () => import(/* webpackChunkName: "stok-birimleri" */ '@/views/tanimlamalar/stokBirimleri/index.vue'),
  },
  {
    path: '/tanimlama/varyant-grup',
    name: 'varyant-gruplari',
    component: () => import(/* webpackChunkName: "varyant-gruplari" */ '@/views/tanimlamalar/variantGrublari/index.vue'),
  },
  {
    path: '/tanimlama/renk-tanimlari',
    name: 'renk-tanimlari',
    component: () => import(/* webpackChunkName: "renk-tanimlari" */ '@/views/tanimlamalar/renkTanimlari/index.vue'),
  },
  {
    path: '/tanimlama/siparis-durumlari',
    name: 'siparis-durumlari',
    component: () => import(/* webpackChunkName: "siparis-durumlari" */ '@/views/tanimlamalar/siparisDurumlari/index.vue'),
  },
  {
    path: '/tanimlama/urun-ek-ozellikler',
    name: 'urun-ek-ozellikler',
    component: () => import(/* webpackChunkName: "urun-ek-ozellikler" */ '@/views/tanimlamalar/urunEkOzellikleri/index.vue'),
  },

  {
    path: '/tanimlama/uye-gruplari',
    name: 'uye-gruplari',
    component: () => import(/* webpackChunkName: "uye-gruplari" */ '@/views/tanimlamalar/uyeGruplari/index.vue'),
  },
  {
    path: '/tanimlama/kargo',
    name: 'kargo',
    component: () => import(/* webpackChunkName: "kargo" */ '@/views/tanimlamalar/kargoIslemleri/index.vue'),
  },
  {
    path: '/tanimlama/kampanyalar',
    name: 'kampanyalar',
    component: () => import(/* webpackChunkName: "kampanyalar" */ '@/views/tanimlamalar/kampanyalar/index.vue'),
  },
  {
    path: '/tanimlama/kuponlar',
    name: 'kuponlar',
    component: () => import(/* webpackChunkName: "kuponlar" */ '@/views/tanimlamalar/kuponlar/index.vue'),
  },
  {
    path: '/tanimlama/harc',
    name: 'harc-hesaplama',
    redirect: '/tanimlama/harc/grup',
    component: () => import(/* webpackChunkName: "kuponlar" */ '@/views/tanimlamalar/harc/index.vue'),
    children: [
      {
        path: '/tanimlama/harc/fiyatlandirma',
        name: 'harc-fiyatlandirma',
        component: () => import(/* webpackChunkName: "kuponlar" */ '@/views/tanimlamalar/harc/fiyatlandirma/index.vue'),
      },
      {
        path: '/tanimlama/harc/grup',
        name: 'harc-grup',
        component: () => import(/* webpackChunkName: "kuponlar" */ '@/views/tanimlamalar/harc/grup/index.vue'),
      },
      {
        path: '/tanimlama/harc/ulke',
        name: 'harc-ulke',
        component: () => import(/* webpackChunkName: "kuponlar" */ '@/views/tanimlamalar/harc/ulke/index.vue'),
      },
      {
        path: '/tanimlama/harc/ayarlar',
        name: 'harc-ayarlar',
        component: () => import(/* webpackChunkName: "kuponlar" */ '@/views/tanimlamalar/harc/ayarlar/index.vue'),
      }
    ]
  },
];
