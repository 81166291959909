import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  kampanyalar: [],
};
const getters = {
  getKampanyalar: (state) => state.kampanyalar,
};
const mutations = {
  SET_KAMPANYA(state, payload) {
    state.kampanyalar = payload;
  },
  SET_KAMPANYA_PUSH(state, payload) {
    state.kampanyalar.push(payload);
  },
  SET_KAMPANYA_UPDATE(state, payload) {
    let index = state.kampanyalar.findIndex((x) => x.k_no == payload.k_no);
    state.kampanyalar[index] = payload;
  },
  SET_KAMPANYA_DELETE(state, k_no) {
    let altindex = state.kampanyalar.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.kampanyalar.splice(altindex, 1);
    }
    let index = state.kampanyalar.findIndex((x) => x.k_no == k_no);
    state.kampanyalar.splice(index, 1);
  },
};
const actions = {
  async kampanyaListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/kampanya-listele', payload).then((res) => {
      commit('SET_KAMPANYA', res.data.data);
      return res;
    });
  },
  async kampanyaEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kampanya-ekle', data).then((res) => {
      commit('SET_KAMPANYA_PUSH', res.data.data);
      return res;
    });
  },
  async kampanyaGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kampanya-guncelle', data).then((res) => {
      commit('SET_KAMPANYA_UPDATE', res.data.data);
      return res;
    });
  },
  async kampanyaSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kampanya-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async kampanyaSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/kampanya-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_KAMPANYA_DELETE', k_no);
      }
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
