import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  sayfalar: [],
};
const getters = {
  getSayfalar: (state) => state.sayfalar,
};
const mutations = {
  SET_SAYFALAR(state, payload) {
    state.sayfalar = payload;
  },
  SET_SAYFALAR_PUSH(state, payload) {
    state.sayfalar.push(payload);
  },
  SET_SAYFALAR_UPDATE(state, payload) {
    let index = state.sayfalar.findIndex((x) => x.k_no == payload.k_no);
    state.sayfalar[index] = payload;
  },
  SET_SAYFALAR_DELETE(state, k_no) {
    let index = state.sayfalar.findIndex((x) => x.k_no == k_no);
    state.sayfalar.splice(index, 1);
  },
};
const actions = {
  async sayfaListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/magaza/sayfa-listele', payload).then((res) => {
      commit('SET_SAYFALAR', res.data.data);
      return res;
    });
  },
  async sayfaEkle({ commit }, data) {
    return await axiosIns.post('/magaza/sayfa-ekle', data).then((res) => {
      commit('SET_SAYFALAR_PUSH', res.data.data);
      return res;
    });
  },
  async sayfaGuncelle({ commit }, data) {
    return await axiosIns.post('/magaza/sayfa-guncelle', data).then((res) => {
      commit('SET_SAYFALAR_UPDATE', res.data.data);
      return res;
    });
  },
  async sayfaBannerSil({ }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/magaza/sayfa-banner-sil', payload).then((res) => {
      return res;
    });
  },
  async sayfaGorselEkle({ commit }, data) {
    return await axiosIns
      .post('/magaza/sayfa-gorsel-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_SAYFALAR_UPDATE', res.data.data);
        return res;
      });
  },

  async sayfaGorselSiraGuncelle({ }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/magaza/sayfa-gorsel-sira-guncelle', payload).then((res) => {
      return res;
    });
  },
  async sayfaGorselKapakGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/magaza/sayfa-gorsel-kapak', payload).then((res) => {
      commit('SET_SAYFALAR_UPDATE', res.data.data);
      return res;
    });
  },
  async sayfaGorselSil({ }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/magaza/sayfa-gorsel-sil', payload).then((res) => {
      return res;
    });
  },
  async sayfaSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/magaza/sayfa-sil', payload).then((res) => {
      if (res.data.success) commit('SET_SAYFALAR_DELETE', k_no);
      return res;
    });
  },

  async sayfaFindOne({ }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/magaza/sayfa-find-one', payload).then((res) => {
      if (res.data.success === true) return res.data.data;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
