import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  kargo_tanimlari: [],
  kargo_kampanya: {},
};
const getters = {
  getKargoTanimlari: (state) => state.kargo_tanimlari,
  getKargoKampanya: (state) => state.kargo_kampanya,
};
const mutations = {
  SET_KAEGOTANIMLA(state, payload) {
    state.kargo_tanimlari = payload;
  },
  SET_KAEGOTANIMLA_PUSH(state, payload) {
    state.kargo_tanimlari.push(payload);
  },
  SET_KAEGOTANIMLA_UPDATE(state, payload) {
    let index = state.kargo_tanimlari.findIndex((x) => x.k_no == payload.k_no);
    state.kargo_tanimlari[index] = payload;
  },
  SET_KAEGOTANIMLA_DELETE(state, k_no) {
    let altindex = state.kargo_tanimlari.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.kargo_tanimlari.splice(altindex, 1);
    }
    let index = state.kargo_tanimlari.findIndex((x) => x.k_no == k_no);
    state.kargo_tanimlari.splice(index, 1);
  },

  SET_KAEGOKAMPANYA(state, payload) {
    state.kargo_kampanya = payload;
  },
};
const actions = {
  async kargoTanimlaListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/kargo-tanimla-listele', payload).then((res) => {
      commit('SET_KAEGOTANIMLA', res.data.data);
      return res;
    });
  },
  async kargoTanimlaEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kargo-tanimla-ekle', data).then((res) => {
      commit('SET_KAEGOTANIMLA_PUSH', res.data.data);
      return res;
    });
  },
  async kargoTanimlaGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kargo-tanimla-guncelle', data).then((res) => {
      commit('SET_KAEGOTANIMLA_UPDATE', res.data.data);
      return res;
    });
  },
  async kargoTanimlaSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kargo-tanimla-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async kargoTanimlaSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/kargo-tanimla-sil', payload).then((res) => {
      commit('SET_KAEGOTANIMLA_DELETE', k_no);
      return res;
    });
  },

  async kargoKampanyaListele({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kargo-kampanya-listele', data).then((res) => {
      commit('SET_KAEGOKAMPANYA', res.data.data);
      return res;
    });
  },

  async kargoKampanyaOlustur({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kargo-kampanya-olustur', data).then((res) => {
      commit('SET_KAEGOKAMPANYA', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
