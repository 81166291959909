import axiosIns from '@/libs/axios';
const state = {
  harc_ayar: {},
};
const getters = {
  getHarcAyar: (state) => state.harc_ayar,
};
const mutations = {
  SET_HARC_AYAR(state, payload) {
    state.harc_ayar = payload;
  },
};
const actions = {
  async harcAyarGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/harc-ayar-getir', payload).then((res) => {
      if (res.data.data != null) {
        commit('SET_HARC_AYAR', res.data.data);
      } else {
        commit('SET_HARC_AYAR', data);
      }
      return res;
    });
  },
  async harcAyarEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/harc-ayar-ekle', payload).then((res) => {
      commit('SET_HARC_AYAR', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
