export default [
  {
    path: '/raporlar/yorumlar',
    name: 'yorumlar',
    redirect: '/raporlar/yorumlar/listele',
    component: () => import(/* webpackChunkName: "yorumlar" */ '@/views/raporlar/yorumlar/index.vue'),
    children: [
      {
        path: '/raporlar/yorumlar/listele',
        name: 'yorum-listele',
        component: () => import(/* webpackChunkName: "yorum-listele" */ '@/views/raporlar/yorumlar/listele.vue'),
      },
    ],
  },
  {
    path: '/raporlar/uyeler',
    name: 'uyeler',
    redirect: '/raporlar/uyeler/listele',
    component: () => import(/* webpackChunkName: "sayfalar" */ '@/views/raporlar/uyeler/index.vue'),
    children: [
      {
        path: '/raporlar/uyeler/listele',
        name: 'uye-listele',
        component: () => import(/* webpackChunkName: "sayfa-listele" */ '@/views/raporlar/uyeler/listele.vue'),
      },
      {
        path: '/raporlar/uyeler/ekle',
        name: 'uye-ekle',
        component: () => import(/* webpackChunkName: "sayfa-ekle" */ '@/views/raporlar/uyeler/uyeEkle.vue'),
      },
      {
        path: '/raporlar/uyeler/guncelle/:k_no?',
        name: 'uye-guncelle',
        component: () => import(/* webpackChunkName: "sayfa-guncelle" */ '@/views/raporlar/uyeler/uyeEkle.vue'),
      },
    ],
  },
  {
    path: '/raporlar/siparis-raporlari',
    name: 'siparis-raporlari',
    component: () => import(/* webpackChunkName: "siparis-raporlari" */ '@/views/magaza/sayfalar/listele.vue'),
  },
  {
    path: '/raporlar/siparis-raporu',
    name: 'siparis-raporu',
    redirect: '/raporlar/siparis-raporu/listele',
    component: () => import(/* webpackChunkName: "siparis-raporu" */ '@/views/raporlar/siparisRaporu/index.vue'),
    children: [
      {
        path: '/raporlar/siparis-raporu/listele',
        name: 'siparis-rapor-listele',
        component: () => import(/* webpackChunkName: "siparis-rapor-listele" */ '@/views/raporlar/siparisRaporu/listele.vue'),
      },
    ],
  },
  {
    path: '/raporlar/urun-satis-raporu',
    name: 'urun-satis-raporu',
    redirect: '/raporlar/urun-satis-raporu/listele',
    component: () => import(/* webpackChunkName: "urun-satis-raporu" */ '@/views/raporlar/urunSatisRaporu/index.vue'),
    children: [
      {
        path: '/raporlar/urun-satis-raporu/listele',
        name: 'urun-satis-rapor-listele',
        component: () =>
          import(/* webpackChunkName: "urun-satis-rapor-listele" */ '@/views/raporlar/urunSatisRaporu/listele.vue'),
      },
    ],
  },
  {
    path: '/raporlar/stok-risk-raporu',
    name: 'stok-risk-raporu',
    redirect: '/raporlar/stok-risk-raporu/listele',
    component: () => import(/* webpackChunkName: "stok-risk-raporu" */ '@/views/raporlar/stokRiskRaporu/index.vue'),
    children: [
      {
        path: '/raporlar/stok-risk-raporu/listele',
        name: 'stok-risk-raporu-listele',
        component: () => import(/* webpackChunkName: "stok-risk-raporu-listele" */ '@/views/raporlar/stokRiskRaporu/listele.vue'),
      },
    ],
  },

  {
    path: '/raporlar/urun-haber-ver-raporu',
    name: 'urun-haber-ver-raporu',
    redirect: '/raporlar/urun-haber-ver-rapor/listele',
    component: () => import(/* webpackChunkName: "yorumlar" */ '@/views/raporlar/haberVerUrun/index.vue'),
    children: [
      {
        path: '/raporlar/urun-haber-ver-rapor/listele',
        name: 'urun-haber-ver-rapor-listele',
        component: () => import(/* webpackChunkName: "yorum-listele" */ '@/views/raporlar/haberVerUrun/listele.vue'),
      },
    ],
  },

  {
    path: '/raporlar/abone-olan-raporu',
    name: 'abone-olan-raporu',
    redirect: '/raporlar/abone-olan-rapor/listele',
    component: () => import(/* webpackChunkName: "abone-olan-raporu" */ '@/views/raporlar/aboneOlanlar/index.vue'),
    children: [
      {
        path: '/raporlar/abone-olan-rapor/listele',
        name: 'abone-olan-rapor-listele',
        component: () => import(/* webpackChunkName: "abone-olan-rapor-listele" */ '@/views/raporlar/aboneOlanlar/listele.vue'),
      },
    ],
  },
];
