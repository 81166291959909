import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  harc_fiyat: [],
};
const getters = {
  getHarcFiyat: (state) => state.harc_fiyat,
};
const mutations = {
  SET_HARC_FIYAT(state, payload) {
    state.harc_fiyat = payload;
  },
  SET_HARC_FIYAT_PUSH(state, payload) {
    state.harc_fiyat.push(payload);
  },
  SET_HARC_FIYAT_UPDATE(state, payload) {
    let index = state.harc_fiyat.findIndex((x) => x.k_no == payload.k_no);
    state.harc_fiyat[index] = payload;
  },
  SET_HARC_FIYAT_DELETE(state, k_no) {
    let altindex = state.harc_fiyat.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.harc_fiyat.splice(altindex, 1);
    }
    let index = state.harc_fiyat.findIndex((x) => x.k_no == k_no);
    state.harc_fiyat.splice(index, 1);
  },
};
const actions = {
  async harcFiyatListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.get('/tanimlamalar/harc-fiyat-listele', { params: payload }).then((res) => {
      commit('SET_HARC_FIYAT', res.data.data);
      return res;
    });
  },
  async harcFiyatEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/harc-fiyat-ekle', data).then((res) => {
      commit('SET_HARC_FIYAT_PUSH', res.data.data);
      return res;
    });
  },
  async harcFiyatGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/harc-fiyat-guncelle', data).then((res) => {
      commit('SET_HARC_FIYAT_UPDATE', res.data.data);
      return res;
    });
  },
  async harcFiyatStatuGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/harc-fiyat-statu-guncelle', data).then((res) => {
      return res;
    });
  },
  async harcFiyatSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/harc-fiyat-sil', payload).then((res) => {
      commit('SET_HARC_FIYAT_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
