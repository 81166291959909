export default [
  {
    path: '/siparis/siparisler',
    name: 'siparisler',
    redirect: '/siparisler/listele',
    component: () => import(/* webpackChunkName: "siparisler" */ '@/views/siparisler/index.vue'),
    children: [
      {
        path: '/siparisler/listele',
        name: 'siparis-listele',
        component: () => import(/* webpackChunkName: "siparis-listele" */ '@/views/siparisler/listele.vue'),
      },
      {
        path: '/siparisler/detay/:k_no?',
        name: 'siparis-detay',
        component: () => import(/* webpackChunkName: "siparis-detay" */ '@/views/siparisler/siparisDetay.vue'),
      },
    ],
  },
  {
    path: '/siparisler/detay/:k_no?/kargo-fisi',
    name: 'kargo-fisi',
    component: () => import(/* webpackChunkName: "kargo-fisi" */ '@/views/siparisler/kargoFisi.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/siparisler/detay/:k_no?/siparis-fisi',
    name: 'siparis-fisi',
    component: () => import(/* webpackChunkName: "siparis-fisi" */ '@/views/siparisler/siparisYazdir.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/siparis/bildirimler',
    name: 'bildirimler',
    component: () => import(/* webpackChunkName: "bildirimler" */ '@/views/siparisler/bildirimFormListesi.vue'),
  },
];
