import Vue from 'vue';
import moment from 'moment';
import store from '@/store';

Vue.filter('cur', (value) => {
  return Number(value).toFixed(2);
});

Vue.filter('moment', (value) => {
  return moment(value).format('DD.MM.YYYY');
});
import slugify from 'slugify';
Vue.filter('momentTime', (value) => {
  return moment(value).format('HH:mm');
});

Vue.filter('momentFull', (value) => {
  return moment(value).format('DD.MM.YYYY HH:mm');
});

Vue.filter('slug', (value) => {
  return value
    .toUpperCase()
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '');
});

Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});

Vue.filter('mask', (value, size = 2) => {
  let result = '';
  let parcala = value.split(' ');
  parcala.forEach((element) => {
    result += element.substr(0, size) + element.substr(2, element.length - size).replace(/./g, '*') + ' ';
  });

  return result;
});

Vue.filter('productImage', (gorseller) => {
  const base_url =
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
      : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

  const db = store.getters.getUserFirma.db;
  if (Object.keys(gorseller).length > 0) {
    const selectImg = gorseller.find((x) => x.kapak == true);
    if (selectImg != undefined) {
      return `${base_url}uploads/${db}/urun/${selectImg.gorsel}`;
    } else {
      return `${base_url}uploads/${db}/urun/${gorseller[0].gorsel}`;
    }
  } else {
    return `${base_url}uploads/no/no-image.jpg`;
  }
});
