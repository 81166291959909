import axiosIns from '@/libs/axios';
const state = {
  abone_olanlar: [],
};
const getters = {
  getAboneOlanRapor: (state) => state.abone_olanlar,
};
const mutations = {
  SET_ABONE_OLAN(state, payload) {
    state.abone_olanlar = payload;
  },
  SET_ABONE_OLAN_PUSH(state, payload) {
    state.abone_olanlar.push(payload);
  },
  SET_ABONE_OLAN_UPDATE(state, payload) {
    let index = state.abone_olanlar.findIndex((x) => x.k_no == payload.k_no);
    state.abone_olanlar[index] = payload;
  },
  SET_ABONE_OLAN_DELETE(state, k_no) {
    let index = state.abone_olanlar.findIndex((x) => x.k_no == k_no);
    state.abone_olanlar.splice(index, 1);
  },
};
const actions = {
  async aboneOlanListele({ commit }, data) {
    return await axiosIns.post('/raporlar/abone-ol-rapor-listele', data).then((res) => {
      commit('SET_ABONE_OLAN', res.data.data);
      return res;
    });
  },

  async aboneOlanRaporSil({ commit }, k_no) {
    return await axiosIns.post('/raporlar/abone-olan-sil', { k_no }).then((res) => {
      commit('SET_ABONE_OLAN_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
