import axiosIns from '@/libs/axios';
const state = {
  ek_ozellikler: [],
};
const getters = {
  getUrunEkOzellikler: (state) => state.ek_ozellikler,
};
const mutations = {
  SET_URUNEKOZELLIK(state, payload) {
    state.ek_ozellikler = payload;
  },
  SET_URUNEKOZELLIK_PUSH(state, payload) {
    state.ek_ozellikler.push(payload);
  },
  SET_URUNEKOZELLIK_UPDATE(state, payload) {
    let index = state.ek_ozellikler.findIndex((x) => x.k_no == payload.k_no);
    state.ek_ozellikler[index] = payload;
  },
  SET_URUNEKOZELLIK_DELETE(state, k_no) {
    let altindex = state.ek_ozellikler.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.ek_ozellikler.splice(altindex, 1);
    }
    let index = state.ek_ozellikler.findIndex((x) => x.k_no == k_no);
    state.ek_ozellikler.splice(index, 1);
  },
};
const actions = {
  async urunEkOzellikListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/urun-ek-ozellik-listele', payload).then((res) => {
      commit('SET_URUNEKOZELLIK', res.data.data);
      return res;
    });
  },
  async urunEkOzellikEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/urun-ek-ozellik-ekle', data).then((res) => {
      commit('SET_URUNEKOZELLIK_PUSH', res.data.data);
      return res;
    });
  },
  async urunEkOzellikGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/urun-ek-ozellik-guncelle', data).then((res) => {
      commit('SET_URUNEKOZELLIK_UPDATE', res.data.data);
      return res;
    });
  },
  async urunEkOzellikSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/urun-ek-ozellik-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async urunEkOzellikSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/urun-ek-ozellik-sil', payload).then((res) => {
      commit('SET_URUNEKOZELLIK_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
