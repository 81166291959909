export default [
  {
    path: '/ayarlar',
    name: 'ayarlar',
    redirect: '/ayarlar/menu',
    component: () => import(/* webpackChunkName: "ayarlar" */ '@/views/ayarlar/index.vue'),
    children: [
      {
        path: '/ayarlar/menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: "menu" */ '@/views/ayarlar/menu/index.vue'),
      },
      {
        path: '/ayarlar/seo',
        name: 'seo',
        component: () => import(/* webpackChunkName: "seo" */ '@/views/ayarlar/seo/index.vue'),
      },
      {
        path: '/ayarlar/iletisim',
        name: 'iletisim',
        component: () => import(/* webpackChunkName: "iletisim" */ '@/views/ayarlar/iletisim/index.vue'),
      },
      {
        path: '/ayarlar/destek',
        name: 'destek',
        component: () => import(/* webpackChunkName: "destek" */ '@/views/ayarlar/destek/index.vue'),
      },
      {
        path: '/ayarlar/dil',
        name: 'dil',
        component: () => import(/* webpackChunkName: "dil" */ '@/views/ayarlar/dil/index.vue'),
      },
      {
        path: '/ayarlar/uyelik',
        name: 'uyelik',
        component: () => import(/* webpackChunkName: "uyelik" */ '@/views/ayarlar/uyelik/index.vue'),
      },
      {
        path: '/ayarlar/kur',
        name: 'kur',
        component: () => import(/* webpackChunkName: "kur" */ '@/views/ayarlar/kur/index.vue'),
      },
      {
        path: '/ayarlar/mail',
        name: 'mail',
        component: () => import(/* webpackChunkName: "mail" */ '@/views/ayarlar/mail/index.vue'),
      },
      {
        path: '/ayarlar/sms',
        name: 'sms',
        component: () => import(/* webpackChunkName: "sms" */ '@/views/ayarlar/sms/index.vue'),
      },
      {
        path: '/ayarlar/sosyal',
        name: 'sosyal',
        component: () => import(/* webpackChunkName: "sosyal" */ '@/views/ayarlar/sosyal/index.vue'),
      },
      {
        path: '/ayarlar/logo',
        name: 'logo',
        component: () => import(/* webpackChunkName: "logo" */ '@/views/ayarlar/logo/index.vue'),
      },
      {
        path: '/ayarlar/firma',
        name: 'firma',
        component: () => import(/* webpackChunkName: "firma" */ '@/views/ayarlar/firma/index.vue'),
      },
      {
        path: '/ayarlar/odeme-sistemi',
        name: 'odeme-sistemi',
        component: () => import(/* webpackChunkName: "odeme-sistemi" */ '@/views/ayarlar/odemeSistemi/index.vue'),
      },
      {
        path: '/ayarlar/banka',
        name: 'banka',
        component: () => import(/* webpackChunkName: "banka" */ '@/views/ayarlar/banka/index.vue'),
      },
      {
        path: '/ayarlar/diger',
        name: 'diger',
        component: () => import(/* webpackChunkName: "diger" */ '@/views/ayarlar/diger/index.vue'),
      },
      {
        path: '/ayarlar/yedekleme',
        name: 'yedekleme',
        component: () => import(/* webpackChunkName: "yedekleme" */ '@/views/ayarlar/yedekleme/index.vue'),
      },
    ],
  },
  {
    path: '/ayarlar/tema-ayar',
    name: 'tema-ayar',
    component: () => import(/* webpackChunkName: "tema-ayar" */ '@/views/ayarlar/temaAyar/index.vue'),
  },
  {
    path: '/kullanici/profilim',
    name: 'profilim',
    component: () => import(/* webpackChunkName: "profilim" */ '@/views/kullanicilar/kullaniciProfil/index.vue'),
  },
];
