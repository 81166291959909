import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  siparis_durumlari: [],
};
const getters = {
  getSiparisDurumlari: (state) => state.siparis_durumlari,
};
const mutations = {
  SET_SIPARISDURUM(state, payload) {
    state.siparis_durumlari = payload;
  },
  SET_SIPARISDURUM_PUSH(state, payload) {
    state.siparis_durumlari.push(payload);
  },
  SET_SIPARISDURUM_UPDATE(state, payload) {
    let index = state.siparis_durumlari.findIndex((x) => x.k_no == payload.k_no);
    state.siparis_durumlari[index] = payload;
  },
  SET_SIPARISDURUM_DELETE(state, k_no) {
    let altindex = state.siparis_durumlari.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.siparis_durumlari.splice(altindex, 1);
    }
    let index = state.siparis_durumlari.findIndex((x) => x.k_no == k_no);
    state.siparis_durumlari.splice(index, 1);
  },
};
const actions = {
  async siparisDurumListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/siparis-durum-listele', payload).then((res) => {
      commit('SET_SIPARISDURUM', res.data.data);
      return res;
    });
  },
  async siparisDurumEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/siparis-durum-ekle', data).then((res) => {
      commit('SET_SIPARISDURUM_PUSH', res.data.data);
      return res;
    });
  },
  async siparisDurumGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/siparis-durum-guncelle', data).then((res) => {
      commit('SET_SIPARISDURUM_UPDATE', res.data.data);
      return res;
    });
  },
  async siparisDurumSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/siparis-durum-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async siparisDurumSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/siparis-durum-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_SIPARISDURUM_DELETE', k_no);
      }
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
