import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  e_tic_urunler: [],
};
const getters = {
  getETicaretUrunler: (state) => state.e_tic_urunler,
};
const mutations = {
  SET_ETICURUNLER(state, payload) {
    state.e_tic_urunler = payload;
  },
  SET_ETICURUNLER_PUSH(state, payload) {
    state.e_tic_urunler.push(payload);
  },
  SET_ETICURUNLER_UPDATE(state, payload) {
    let index = state.e_tic_urunler.findIndex((x) => x.k_no == payload.k_no);
    state.e_tic_urunler[index] = payload;
  },
  SET_ETICURUNLER_DELETE(state, k_no) {
    let index = state.e_tic_urunler.findIndex((x) => x.k_no == k_no);
    state.e_tic_urunler.splice(index, 1);
  },
};
const actions = {
  async eTicUrunListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/e-tic-urun-listele', payload).then((res) => {
      commit('SET_ETICURUNLER', res.data.data);
      return res;
    });
  },
  async eTicUrunEkle({ commit }, data) {
    return await axiosIns.post('/urunler/e-tic-urun-ekle', data).then((res) => {
      commit('SET_ETICURUNLER_PUSH', res.data.data);
      return res;
    });
  },
  async eTicUrunGuncelle({ commit }, data) {
    return await axiosIns.post('/urunler/e-tic-urun-guncelle', data).then((res) => {
      commit('SET_ETICURUNLER_UPDATE', res.data.data);
      return res;
    });
  },
  async eTicUrunBannerSil({ }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/e-tic-urun-banner-sil', payload).then((res) => {
      return res;
    });
  },
  async eTicUrunGorselEkle({ commit }, data) {
    return await axiosIns
      .post('/urunler/e-tic-urun-gorsel-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_ETICURUNLER_UPDATE', res.data.data);
        return res;
      });
  },
  async eTicUrunGorselSiraGuncelle({ }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/urunler/e-tic-urun-gorsel-sira-guncelle', payload).then((res) => {
      return res;
    });
  },
  async eTicUrunGorselKapakGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/e-tic-urun-gorsel-kapak', payload).then((res) => {
      commit('SET_ETICURUNLER_UPDATE', res.data.data);
      return res;
    });
  },
  async eTicUrunGorselSil({ }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/e-tic-urun-gorsel-sil', payload).then((res) => {
      return res;
    });
  },
  async eTicUrunSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/urunler/e-tic-urun-sil', payload).then((res) => {
      commit('SET_ETICURUNLER_DELETE', k_no);
      return res;
    });
  },
  async eTicTopluUrunSil({ commit }, payload) {
    return await axiosIns.post('/urunler/e-tic-toplu-urun-sil', payload).then((res) => {
      if (res.data.success == true) {
        payload.k_no.forEach((element) => {
          commit('SET_ETICURUNLER_DELETE', element.k_no);
        });
      }
      return res;
    });
  },
  async eTicUrunKopyala({ commit }, payload) {
    return await axiosIns.post('/urunler/e-tic-urun-kopyala', payload).then((res) => {
      return res;
    });
  },
  async eTicTopluUrunGuncelle({ commit }, payload) {
    return await axiosIns.post('/urunler/e-tic-toplu-urun-guncelle', payload).then((res) => {
      return res;
    });
  },
  async eTicUrunFindOne({ }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/urunler/e-tic-urun-find-one', payload).then((res) => {
      if (res.data.success === true) return res.data.data;
    });
  },

  async eTicUrunSiralamaListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.get('/urunler/e-tic-urun-siralama-listele', { params: payload }).then((res) => {
      commit('SET_URUNLER', res.data.data);
      return res;
    });
  },
  async eTicUrunSiralamaGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/e-tic-urun-siralama-guncelle', payload).then((res) => {
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
