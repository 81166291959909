import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  stok_birimler: [],
};
const getters = {
  getStokBirimler: (state) => state.stok_birimler,
};
const mutations = {
  SET_STOKBIRIM(state, payload) {
    state.stok_birimler = payload;
  },
  SET_STOKBIRIM_PUSH(state, payload) {
    state.stok_birimler.push(payload);
  },
  SET_STOKBIRIM_UPDATE(state, payload) {
    let index = state.stok_birimler.findIndex((x) => x.k_no == payload.k_no);
    state.stok_birimler[index] = payload;
  },
  SET_STOKBIRIM_DELETE(state, k_no) {
    let altindex = state.stok_birimler.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.stok_birimler.splice(altindex, 1);
    }
    let index = state.stok_birimler.findIndex((x) => x.k_no == k_no);
    state.stok_birimler.splice(index, 1);
  },
};
const actions = {
  async stokBirimListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/stok-birim-listele', payload).then((res) => {
      commit('SET_STOKBIRIM', res.data.data);
      return res;
    });
  },
  async stokBirimEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/stok-birim-ekle', data).then((res) => {
      commit('SET_STOKBIRIM_PUSH', res.data.data);
      return res;
    });
  },
  async stokBirimGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/stok-birim-guncelle', data).then((res) => {
      commit('SET_STOKBIRIM_UPDATE', res.data.data);
      return res;
    });
  },
  async stokBirimSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/stok-birim-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async stokBirimSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/stok-birim-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_STOKBIRIM_DELETE', k_no);
      }
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
