export default [
  {
    path: '/magaza/sayfalar',
    name: 'sayfalar',
    redirect: '/magaza/sayfa/listele',
    component: () => import(/* webpackChunkName: "sayfalar" */ '@/views/magaza/sayfalar/index.vue'),
    children: [
      {
        path: '/magaza/sayfa/listele',
        name: 'sayfa-listele',
        component: () => import(/* webpackChunkName: "sayfa-listele" */ '@/views/magaza/sayfalar/listele.vue'),
      },
      {
        path: '/magaza/sayfa/ekle',
        name: 'sayfa-ekle',
        component: () => import(/* webpackChunkName: "sayfa-ekle" */ '@/views/magaza/sayfalar/sayfaEkle.vue'),
      },
      {
        path: '/magaza/sayfa/guncelle/:k_no?',
        name: 'sayfa-guncelle',
        component: () => import(/* webpackChunkName: "sayfa-ekle" */ '@/views/magaza/sayfalar/sayfaEkle.vue'),
      },
    ],
  },
  {
    path: '/magaza/slider',
    name: 'slider',
    component: () => import(/* webpackChunkName: "slider" */ '@/views/magaza/slider/index.vue'),
  },
  {
    path: '/magaza/banner',
    name: 'banner',
    component: () => import(/* webpackChunkName: "banner" */ '@/views/magaza/banner/index.vue'),
  },
  {
    path: '/magaza/splash-bildirimler',
    name: 'splash-bildirimler',
    component: () => import(/* webpackChunkName: "splash-bildirimler" */ '@/views/magaza/splashBildirimler/index.vue'),
  },
  {
    path: '/magaza/medya-ortam',
    name: 'medya-ortam',
    component: () => import(/* webpackChunkName: "splash-bildirimler" */ '@/views/magaza/medyaOrtam/index.vue'),
    children: [
      {
        path: '/magaza/medya-ortam/ekle',
        name: 'medya-ortam-ekle',
        component: () => import(/* webpackChunkName: "medya-ortam-ekle" */ '@/views/magaza/medyaOrtam/component/MedyaOrtamEkle.vue'),
      },
      {
        path: '/magaza/medya-ortam/guncelle/:k_no?',
        name: 'medya-ortam-guncelle',
        component: () => import(/* webpackChunkName: "medya-ortam-ekle" */ '@/views/magaza/medyaOrtam/component/MedyaOrtamEkle.vue'),
      },
      {
        path: '/magaza/medya-ortam/yukle/:k_no?',
        name: 'medya-ortam-yukle',
        component: () => import(/* webpackChunkName: "medya-ortam-yukle" */ '@/views/magaza/medyaOrtam/component/MedyaOrtamDosyalar.vue'),
      },
    ]
  },
];
